import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CarouselSlider = () => {
  const settings = {
    className: "center-slider",
    centerMode: true,
    infinite: true,
    centerPadding: "300px",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="client-stories">
        <div className="container-fluid">
          <div className="row">
            <h3 className="client-title sub-title">Our Client Stories</h3>
            <div className="slider-container">
              <Slider {...settings}>
                <div className="client-cc">
                  <p>
                    I’ve had several projects with the team at Yati Labs over
                    the past few years. In that time they’ve been extremely
                    professional, punctual, and have shown a deep knowledge of
                    software development. They have a gift in paying attention
                    to detail and bringing an idea on my mind to life! They are
                    my “go to” for any software projects we have at Fine Dine
                    App.
                  </p>
                  <div className="client-img">
                    <img src="../assets/Images/carulose-1.svg" alt="" />
                    <p>Joshua Liggins</p>
                    <p>CEO at FineDine</p>
                  </div>
                </div>
                <div className="client-cc">
                  <p>
                    They are a pleasure to deal with. They will take the time to
                    understand what you want, provide real guidance and insight,
                    and work with you to get the final product you want. I would
                    definitely work with them again on the next project.
                  </p>
                  <div className="client-img">
                    <img src="../assets/Images/carulose-1.svg" alt="" />
                    <p>Joshua Liggins</p>
                    <p>CEO at FineDine</p>
                  </div>
                </div>
                <div className="client-cc">
                  <p>
                    They are great to work with. Been four years working with
                    them, here are some key takeaways : only commit what they
                    can deliver, cost efficient, 24/7 support, their design team
                    is the best.
                  </p>
                  <div className="client-img">
                    <img src="../assets/Images/carulose-1.svg" alt="" />
                    <p>Joshua Liggins</p>
                    <p>CEO at FineDine</p>
                  </div>
                </div>
                <div className="client-cc">
                  <p>
                    They are great to work with. Been four years working with
                    them, here are some key takeaways : only commit what they
                    can deliver, cost efficient, 24/7 support, their design team
                    is the best.
                  </p>
                  <div className="client-img">
                    <img src="../assets/Images/carulose-1.svg" alt="" />
                    <p>Joshua Liggins</p>
                    <p>CEO at FineDine</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarouselSlider;
