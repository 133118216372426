
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from "./Pages/Index";
import WhatWeDo from "./Pages/WhatWeDo";
import Work from "./Pages/Work.jsx";
import Insights from "./Pages/Insights";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path='/what-we-do' element={<WhatWeDo />} />
        <Route path='/work' element={<Work />} />
        <Route path='/insights' element={< Insights />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </BrowserRouter >
  );
}

export default App;
