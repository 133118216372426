import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const MainLayout = ({ children, className }) => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <div className={`wrapper-body ${className} `}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
