import React from "react";
import MainLayout from "../Layout/MainLayout";

const About = () => {
  return (
    <>
      <MainLayout></MainLayout>
    </>
  );
};

export default About;
