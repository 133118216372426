import React from "react";
import MainLayout from "../Layout/MainLayout";
import { Link } from "react-router-dom";
import CarouselSlider from "../Components/CarouselSlider";
import InsightsList from "../Components/InsightsList";

const Work = () => {
  return (
    <MainLayout>
      <>
        <section className="work gradient">
          <div className="container-fluid">
            <h1 className="heading-main">Web or mobile, engage with ease.</h1>
            <h4>
              We've got you <span>covered.</span>{" "}
            </h4>
            <div className="count-number">
              <div className="number">
                <h5>40,000+</h5>
                <p>Hours completed</p>
              </div>
              <div className="number">
                <h5>100+</h5>
                <p>Clients</p>
              </div>
              <div className="number">
                <h5>300+</h5>
                <p>Projects delivered</p>
              </div>
            </div>
          </div>
        </section>
        <section className="providing">
          <div className="container-fluid">
            <div className="row">
              <div className="hand-providing"></div>
              <div className="banner-section">
                <h3>
                  We're dedicated to providing comprehensive IT solutions
                  tailored to meet <span>your unique needs</span>.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="blog">
          <div className="blog-text">
            <img src="../assets/Images/check-icon.png" alt="" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
            >
              <g filter="url(#filter0_d_293_1269)">
                <path
                  d="M3 9.84615L7.11429 14L19 2"
                  stroke="url(#paint0_linear_293_1269)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_293_1269"
                  x="0"
                  y="0"
                  width="24"
                  height="20"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1" dy="2" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_293_1269"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_293_1269"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_293_1269"
                  x1="19.4571%"
                  y1="-7.23077%"
                  x2="-1.31114%"
                  y2="13.3397%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="1" stop-color="#5BF7D0" />
                  <stop stop-color="#FF9800" />
                </linearGradient>
              </defs>
            </svg> */}
            <p>Cutting-Edge Technology Solutions</p>
          </div>
          <div className="blog-text">
            <img src="../assets/Images/check-icon.png" alt="" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
            >
              <g filter="url(#filter0_d_293_1269)">
                <path
                  d="M3 9.84615L7.11429 14L19 2"
                  stroke="url(#paint0_linear_293_1269)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_293_1269"
                  x="0"
                  y="0"
                  width="24"
                  height="20"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1" dy="2" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_293_1269"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_293_1269"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_293_1269"
                  x1="19.4571%"
                  y1="-7.23077%"
                  x2="-1.31114%"
                  y2="13.3397%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="1" stop-color="#5BF7D0" />
                  <stop stop-color="#FF9800" />
                </linearGradient>
              </defs>
            </svg> */}
            <p>Experienced Team of Professionals</p>
          </div>
          <div className="blog-text">
            <img src="../assets/Images/check-icon.png" alt="" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
            >
              <g filter="url(#filter0_d_293_1269)">
                <path
                  d="M3 9.84615L7.11429 14L19 2"
                  stroke="url(#paint0_linear_293_1269)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_293_1269"
                  x="0"
                  y="0"
                  width="24"
                  height="20"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1" dy="2" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_293_1269"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_293_1269"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_293_1269"
                  x1="19.4571%"
                  y1="-7.23077%"
                  x2="-1.31114%"
                  y2="13.3397%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="1" stop-color="#5BF7D0" />
                  <stop stop-color="#FF9800" />
                </linearGradient>
              </defs>
            </svg> */}
            <p>On-Time, OnBudget Delivery</p>
          </div>
          <div className="blog-text">
            <img src="../assets/Images/check-icon.png" alt="" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
            >
              <g filter="url(#filter0_d_293_1269)">
                <path
                  d="M3 9.84615L7.11429 14L19 2"
                  stroke="url(#paint0_linear_293_1269)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_293_1269"
                  x="0"
                  y="0"
                  width="24"
                  height="20"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1" dy="2" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_293_1269"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_293_1269"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_293_1269"
                  x1="19.4571%"
                  y1="-7.23077%"
                  x2="-1.31114%"
                  y2="13.3397%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="1" stop-color="#5BF7D0" />
                  <stop stop-color="#FF9800" />
                </linearGradient>
              </defs>
            </svg> */}
            <p>Dedicated Project Management</p>
          </div>
          <div className="blog-text">
            <img src="../assets/Images/check-icon.png" alt="" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
            >
              <g filter="url(#filter0_d_293_1269)">
                <path
                  d="M3 9.84615L7.11429 14L19 2"
                  stroke="url(#paint0_linear_293_1269)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_293_1269"
                  x="0"
                  y="0"
                  width="24"
                  height="20"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1" dy="2" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_293_1269"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_293_1269"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_293_1269"
                  x1="19.4571%"
                  y1="-7.23077%"
                  x2="-1.31114%"
                  y2="13.3397%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="1" stop-color="#5BF7D0" />
                  <stop stop-color="#FF9800" />
                </linearGradient>
              </defs>
            </svg> */}
            <p>Transparent Communication</p>
          </div>
        </section>
        <section className="choose">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <h3>Why Choose Us? </h3>
              </div>
              <div className="col-lg-8">
                <h4>
                  With a proven track record of success and a commitment to
                  exceeding client expectations, <span>YatiLabs</span> is your
                  trusted partner for all your IT needs.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="our-services">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <div className="o-s">
                  <h4 className="sub-title">Our Services</h4>
                </div>
              </div>
              <div className="col-lg-8">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="one">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        App Development
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="one"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Web Development
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Product Design
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        Strategy & Innovation
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Research & Insights
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        DevOps Services
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="it-language">
                  <div className="technology">
                    <h3>Custom Software Development</h3>
                    <p>
                      From concept to implementation, we develop bespoke
                      software solutions that align perfectly with your business
                      objectives.
                    </p>
                  </div>
                  <div className="technology">
                    <h3>IT Consulting</h3>
                    <p>
                      Get expert advice and guidance on how to leverage
                      technology to drive growth and efficiency in your
                      organisation.
                    </p>
                  </div>
                  <div className="technology">
                    <h3>Web Development</h3>
                    <p>
                      Elevate your online presence with beautifully designed,
                      user-friendly websites that leave a lasting impression.
                    </p>
                  </div>
                  <div className="technology">
                    <h3>Cloud Solutions</h3>
                    <p>
                      Embrace the flexibility and scalability of cloud computing
                      with our tailored cloud solutions designed to meet your
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="personal  personal-work">
          <div className="container">
            <div className="row">
              <div className="vision gradient">
                <p className="text-pp">
                  Your Vision + Our Innovation <br />
                  <span>You. Are The Inspiration.</span>
                </p>
                <Link to="#" className="theme-button">
                  Start Your Project
                </Link>
                <p className="d-none d-md-block">
                  We’ve worked with clients of all sizes, from enterprise brands
                  to funded startups. Let’s talk about your project and how we
                  can help provide value.
                </p>
              </div>
            </div>
          </div>
        </section>
        <CarouselSlider />
        <section className="project">
          <div className="container-fluid">
            <div className="row">
              <div className="create-project">
                <h3 className="sub-title">Featured Projects</h3>
                <div class="card mb-3">
                  <div class="row g-5">
                    <div class="col-md-6">
                      <img
                        src="../assets/Images/project-one.png"
                        class="img-fluid rounded-start"
                        alt="project-one"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="card-body">
                        <p class="card-text">New delivery app with</p>
                        <h5 class="card-title">Careem Now</h5>
                        <p className="card-sub-text">
                          Delighting you with your favourite meals, delivered
                          directly to anywhere you are.
                        </p>
                        <Link to="#">View case study</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3 mt-5">
                  <div class="row g-5">
                    <div class="col-md-6">
                      <img
                        src="../assets/Images/project-two.png"
                        class="img-fluid rounded-start"
                        alt="project-one"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="card-body">
                        <p class="card-text">
                          An intuitive one-click cab booking app
                        </p>
                        <h5 class="card-title">Cab Info</h5>
                        <p className="card-sub-text">
                          Cab Info is a user-friendly mobile application
                          designed to simplify the process of booking cabs with
                          just a click. Offering detailed information about
                          available cabs, including driver details, vehicle
                          specifications, and estimated arrival times
                        </p>
                        <Link to="#">View case study</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <InsightsList />
      </>
    </MainLayout>
  );
};

export default Work;
