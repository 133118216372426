import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="footer-listing gradient">
              <div className="f-logo">
                <img src="../assets/Images/footer-logo.svg" alt="" />
                <p>You Are The Inspiration</p>
              </div>
              <div className="yatilabs-all-branch">
                <div className="branch">
                  <h3>US</h3>
                  <p>4990 Lighthouse Cir Apt E, 33063 Coconut Creek, FL.</p>
                </div>
                <div className="branch">
                  <h3>Dubai</h3>
                  <p>Office No. 201, Saeed Al Tayer Building Al Rafaa Area.</p>
                </div>
                <div className="branch">
                  <h3>India</h3>
                  <p>SCF 46, First Floor Sector 4, 134112. Panchkula.</p>
                </div>
              </div>
            </div>
            <div className="footer-list">
              <ul>
                <li>
                  <Link to="">Home</Link>
                </li>
                <li>
                  <Link to="">Work</Link>
                </li>
                <li>
                  <Link to="">Insights</Link>
                </li>
                <li>
                  <Link to="">Contact</Link>
                </li>
              </ul>
              <p>© 2024 Yatilabs.com. All Rights Reserved. </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
