import React from "react";
import MainLayout from "../Layout/MainLayout";

const Contact = () => {
  return (
    <>
      <MainLayout></MainLayout>
    </>
  );
};

export default Contact;
