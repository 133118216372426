import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [activeItem, setActiveItem] = useState("");
  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  return (
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar-listing">
              <Link to="/" className="logo">
                <img src="../assets/Images/logo.svg" alt="logo" />
              </Link>

              <input type="checkbox" id="check-input" />
              <label htmlFor="check-input" className="click-img">
                <img src="../assets/Images/hamburger.svg" alt="" />
              </label>

              <ul>
                <li>
                  <Link className={activeItem === "/" ? "active" : ""} to="/">
                    What We Do
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeItem === "/work" ? "active" : ""}
                    to="/work"
                  >
                    Work
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeItem === "/insights" ? "active" : ""}
                    to="/insights"
                  >
                    Insights
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeItem === "/about" ? "active" : ""}
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeItem === "/contact" ? "active" : ""}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="d-none d-md-block">
                <img src="../assets/Images/hamburger.svg" alt="" />
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
