import React from "react";

const InsightsList = () => {
  return (
    <>
      <section className="insights">
        <div className="container-fluid">
          <div className="row">
            <div className="insights-listing">
              <p className="sub-title">Insights</p>

              <div className="ux-design">
                <img
                  src="../assets/Images/image-ins.svg"
                  alt="mage-ins"
                  className="img-fluid"
                />
                <div className="ux-design-list">
                  <p>Jan 10, 2024</p>
                  <h4>
                    Top App Ideas For Starting up Your Entrepreneurial Journey
                    in 2024
                    <span>
                      <img src="../assets/Images/icon-top.svg" alt="" />
                    </span>
                  </h4>
                </div>
              </div>
              <div className="ux-design">
                <img
                  src="../assets/Images/grid-img.svg"
                  alt="mage-ins"
                  className="img-fluid"
                />
                <div className="ux-design-list">
                  <p>March 11, 2023</p>
                  <h4>
                    What Is UI/UX Design? A Comprehensive Guide
                    <span>
                      <img src="../assets/Images/icon-top.svg" alt="" />
                    </span>
                  </h4>
                </div>
              </div>
              <div className="ux-design">
                <img
                  src="../assets/Images/dev-img-licking.svg"
                  alt="mage-ins"
                  className="img-fluid"
                />
                <div className="ux-design-list">
                  <p>Feb 17, 2023</p>
                  <h4>
                    Minimum Viable Product: The Do’s and Dont’s For Testing Your
                    App Idea
                    <span>
                      <img src="../assets/Images/icon-top.svg" alt="" />
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InsightsList;
